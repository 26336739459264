import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import { mustNumbers, emptySpace, autocloseTiming } from "../../../api/regex";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { FiHelpCircle } from "react-icons/fi";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const FederalTaxSetup = (props) => {
  const {
    nextStep,
    onboardObject,
    federalList,
    handleGetEmployeeData,
    summaryPage,
  } = props;
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeObject, setEmployeeObject] = useState({
    filing_status: "",
    other_dependents: "",
    multiple_jobs: "0",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (onboardObject?.federal_tax) {
      setEmployeeObject(onboardObject?.federal_tax);
    }
  }, [onboardObject]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEmployeeObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const renderTooltip = (
    <div>
      Includes spouse (if applicable). Answering 2c results in higher
      withholding, but to preserve privacy this can be left unchecked. To learn
      more, read the
      <a
        href="https://www.irs.gov/newsroom/faqs-on-the-2020-form-w-4"
        target="_blank"
        without
        rel="noreferrer"
      >
        {" "}
        IRS’s instructions.
      </a>
    </div>
  );

  const findFormErrors = () => {
    const {
      filing_status,
      other_dependents,
      other_income,
      extra_withholding,
      deductions,
    } = employeeObject;
    const newErrors = {};
    if (!filing_status || filing_status === "")
      newErrors.filing_status = "Please enter your federal filing status .";
    if (
      other_dependents !== "" &&
      other_dependents !== undefined &&
      other_dependents !== null
    ) {
      if (!other_dependents.toString()?.match(emptySpace)) {
        newErrors.other_dependents = "The dependent total should be numbers.";
      } else if (!other_dependents.toString()?.match(/^\d+(\.\d{1,2})?$/)) {
        newErrors.other_dependents =
          "Enter a valid number with up to two decimal places.";
      } else if (other_dependents < 0 || other_dependents > 1000000000) {
        newErrors.other_dependents =
          "The dependent total must be less than or equal to 1000000000.";
      }
    }
    if (
      other_income !== "" &&
      other_income !== undefined &&
      other_income !== null
    ) {
      if (!other_income.toString()?.match(/^\d+(\.\d{1,2})?$/)) {
        newErrors.other_income =
          "Enter a valid number with up to two decimal places.";
      } else if (other_income < 0 || other_income > 1000000000) {
        newErrors.other_income =
          "The  other income must be less than or equal to 1000000000.";
      }
    }
    if (
      extra_withholding !== "" &&
      extra_withholding !== undefined &&
      extra_withholding !== null
    ) {
      if (!extra_withholding.toString()?.match(/^\d+(\.\d{1,2})?$/)) {
        newErrors.extra_withholding =
          "Enter a valid number with up to two decimal places.";
      } else if (extra_withholding < 0 || extra_withholding > 1000000000) {
        newErrors.extra_withholding =
          "The extra withholding  must be less than or equal to 1000000000.";
      }
    }
    if (deductions !== "" && deductions !== undefined && deductions !== null) {
      if (!deductions.toString()?.match(/^\d+(\.\d{1,2})?$/)) {
        newErrors.deductions =
          "Enter a valid number with up to two decimal places.";
      } else if (deductions < 0 || deductions > 1000000000) {
        newErrors.deductions =
          "The deductions  must be less than or equal to 1000000000.";
      }
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      federalTaxSetup();
    }
  };

  const federalTaxSetup = async () => {
    setLoading(true);
    let data = {
      ...employeeObject,
      filing_status: +employeeObject.filing_status,
      multiple_jobs: +employeeObject.multiple_jobs,
    };
    setLoading(true);
    const res = await postData("gusto-employee-federal-tax", {}, data);
    if (res.status === true) {
      setLoading(false);
      nextStep(res.next_steps);
      handleGetEmployeeData();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleJobsChange = (e) => {
    setEmployeeObject((prev) => ({
      ...prev,
      multiple_jobs: e.target.value,
    }));
  };
  return (
    <>
      {!loading && (
        <div className="board-right board-tab-sections">
          <h5 className="mb-3"> Federal tax </h5>

          <h4 className="emp-sub-title mb-3">
            Step 1: Go to the IRS Calculator
          </h4>
          <div className="employee-title">
            Enter your entity type and the legal name of your company. you can
            find this info on your{" "}
            <a
              href="https://www.irs.gov/businesses/small-businesses-self-employed/lost-or-misplaced-your-ein"
              target="_blank"
              without
              rel="noreferrer"
            >
              FEIN assignment form (Form CP575)
            </a>
            . We need this to file and pay your taxes correctly.
          </div>
          <div className="employee-points mb-4">
            What to have ready:
            <ul>
              <li>Total household Income</li>
              <li>Most Recent Pay stub(if any)</li>
              <li>Most Recent Tax Return(if any)</li>
              <li>W-4 form</li>
            </ul>
          </div>

          {/* step 2 */}
          <h4 className="emp-sub-title mb-3">Step 2: Set up withholdings</h4>
          <div className="employee-points mb-4">
            Verify the sources below for any requirements to set up
            withholdings:
            <ul className="active">
              <li>
                <a
                  href="https://www.irs.gov/newsroom/faqs-on-the-2020-form-w-4"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  IRS instructions
                </a>
              </li>
              <li>
                <a
                  href="https://www.irs.gov/individuals/tax-withholding-estimator"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  IRS calculator
                </a>
              </li>
              <li>
                <a
                  href="https://www.irs.gov/pub/irs-pdf/fw4.pdf"
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  W-4 form{" "}
                </a>
              </li>
            </ul>
          </div>

          <div className="employee-fedral mb-4">
            <h4 className="emp-sub-title mb-3">Federal filing status (1c)</h4>
            If you select Exempt from withholding, we wonâ€™t withhold federal
            income taxes, but weâ€™ll still report taxable wages on a W-2. Keep
            in mind that anyone who claims exemption from with holding needs to
            submit a new W-4 each year.
          </div>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            autoComplete="off"
            className="custom-form"
          >
            <Row>
              <Col md={8}>
                <Form.Group className="mb-3" controlId="filing_status">
                  <Form.Label>
                    Federal filing status (1C){" "}
                    <span className="asterisk">*</span>{" "}
                  </Form.Label>
                  <Form.Select
                    onChange={handleInput}
                    name="filing_status"
                    value={employeeObject.filing_status}
                    required
                    size="lg"
                    disabled={summaryPage}
                  >
                    <option value="">Select Federal filing status</option>
                    {federalList &&
                      federalList.map((item, index) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.filing_status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="jobs">
                  <Form.Label className="me-2">
                    Multiple jobs (2C) (optional)
                  </Form.Label>
                  <Tooltip placement="right" overlay={renderTooltip}>
                    <FiHelpCircle className="help-circle" />
                  </Tooltip>
                  <div>
                    <Form.Check
                      inline
                      label="Yes"
                      name="jobs"
                      type="radio"
                      checked={
                        employeeObject.multiple_jobs == "1" ? true : false
                      }
                      value="1"
                      onChange={(e) => handleJobsChange(e)}
                      id="Yes"
                      disabled={summaryPage}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="jobs"
                      type="radio"
                      checked={
                        employeeObject.multiple_jobs == "0" ? true : false
                      }
                      value="0"
                      onChange={(e) => handleJobsChange(e)}
                      id="No"
                      disabled={summaryPage}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.jobs}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="other_dependents">
                  <Form.Label>Dependent total (3) (If applicable) </Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Enter the results for line 3 from the IRS calculator or form W-4."
                    value={employeeObject.other_dependents}
                    onChange={handleInput}
                    isInvalid={!!errors.other_dependents}
                    name="other_dependents"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.other_dependents}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="other_income">
                  <Form.Label>Other income (4a) </Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Enter the results for line 4a from the IRS calculator or form W-4."
                    value={employeeObject.other_income}
                    onChange={handleInput}
                    isInvalid={!!errors.other_income}
                    name="other_income"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.other_income}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="deductions">
                  <Form.Label>Deductions (4b) </Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Enter the results for line 4b from the IRS calculator or form W-4."
                    value={employeeObject.deductions}
                    onChange={handleInput}
                    isInvalid={!!errors.deductions}
                    name="deductions"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.deductions}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="extra_withholding">
                  <Form.Label>Extra withholding (4c) </Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Enter the results for line 4c from the IRS calculator or form W-4."
                    value={employeeObject.extra_withholding}
                    onChange={handleInput}
                    isInvalid={!!errors.extra_withholding}
                    name="extra_withholding"
                    disabled={summaryPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.extra_withholding}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {summaryPage ? null : (
              <div className="text-end mt-4">
                <Button
                  type="button"
                  className="btn-outline-primary me-2"
                  onClick={() => nextStep("direct_deposit")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  className="btn-primary text-white"
                >
                  Save & Continue
                </Button>
              </div>
            )}
          </Form>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default FederalTaxSetup;
