import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import "./style.scss";
import OnboardHeading from "../../../components/OnboardHeading";
import Steper from "../../../components/StepperProgress";
import HeaderForm from "../../../components/HeaderForm";
import PersonalInformation from "./PersonalInformation";
// import ContactInfomation from "./ContactInfomation";
import FederalTaxSetup from "./FederalTaxSetup";
// import EmergencyContact from "./EmergencyContact";
import DirectDeposit from "./DirectDeposit";
import { postData, getData, getRevertUser } from "../../../api";
import { toast } from "react-toastify";
import OnboardingsTask from "./OnboardingsTask";
import EligibilityForm from "./EligibilityForm";
import DocumentUpload from "./DocumentUpload";
import Loader from "../../../components/Loader";
import revertIcon from "../../../assets/images/fa_refresh.svg";
import { autocloseTiming } from "../../../api/regex";
import EmployeeAgreement from "./EmployeeAgreement";
import { EmployeeAgreementContext } from "../../../hooks/Context/EmployeeAgreementContext";
import OfferLetter from "./offerLetter";
import ProgressBar from "./progressBar";
import EmployeeWelcomScreen from "./employeeWelcomeScreen";
import DocumentVerification from "./DocumentVerification";
import EmployeeOnboardingSummary from "./EmployeeOnboardingSummary";
import StatetaxTab from "../Statetax";
// import EmployerStateTax from "../../EmployerStateTax";
// import { TabList } from "@mui/lab";
import { useParams, useLocation } from "react-router-dom";

const EmployeeOnboardingForms = () => {
  const { step: queryStep } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(queryStep || "personal_info");
  const [stepNo, setStepNo] = useState();
  const [title, setTitle] = useState();
  const [employeeObject, setEmployeeObject] = useState({});
  const [stepArrays, setStepArrays] = useState([]);
  const [stateList, setStateList] = useState();
  const [relationshipList, setRelationshipList] = useState([]);
  const [federalList, setFederalList] = useState();
  const [eligibilityList, setEligibilityList] = useState();
  const [countryList, setCountryList] = useState();
  const [tabsList, setTabsList] = useState([]);
  const [newCompanyName, setNewCompanyName] = useState();
  setTimeout(() => {
    const companyName = localStorage.getItem("company_name");
    setNewCompanyName(companyName);
  }, 3000);
  const [empAgreeTabActive, setEmpAgreeTabActive] = useState(null);
  const [reloadContactInfo, setReloadContactInfo] = useState(false);
  const { setEmpAgreeValue } = useContext(EmployeeAgreementContext);
  const [completedSteps, setCompletedSteps] = useState([]);

  useEffect(() => {
    // getClientStep();
    getStaticList();
  }, []);
  useEffect(() => {
    getTabTitle(step);
    if (step === "contact_info") {
      setEmpAgreeValue(true);
      setReloadContactInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   tabActive();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [newTabArray]);

  const updateProgressBar = (currentStep) => {
    const currentIndex = stepArrays.indexOf(currentStep);
    if (currentIndex !== -1) {
      const completed = stepArrays.slice(0, currentIndex);
      setCompletedSteps(completed);
    }
  };
  useEffect(() => {
    updateProgressBar(step);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, stepArrays]);

  // const activeTabChange = async (step) => {
  //   let stepIndex = null;
  //   for (let index = 0; index < stepArrays.length; index++) {
  //     const element = stepArrays[index];
  //     if (step === element) {
  //       stepIndex = index + 1;
  //     }
  //   }
  //   let newElement = stepArrays.splice(0, stepIndex);
  //   setNewTabArray([...newTabArray, ...newElement]);
  // };

  // const tabActive = () => {
  //   if (newTabArray.includes("personal_info")) {
  //     setPersonalTabActive("active");
  //   }
  //   if (newTabArray.includes("contact_info")) {
  //     setContactTabActive("active");
  //   }
  //   if (newTabArray.includes("employee_agreement")) {
  //     setEmpAgreeTabActive("active");
  //   }
  //   if (newTabArray.includes("emergency_contact")) {
  //     setEmergencyTabActive("active");
  //   }
  //   if (newTabArray.includes("federal_tax")) {
  //     setFedralTabActive("active");
  //   }
  //   if (newTabArray.includes("onboarding_tasks")) {
  //     setOnboardTaskTabActive("active");
  //   }
  //   if (newTabArray.includes("direct_deposit")) {
  //     setDepositTabActive("active");
  //   }
  //   if (newTabArray.includes("file_hire_new_report")) {
  //     setFileTabActive("active");
  //   }
  //   if (newTabArray.includes("eligibility_acknowledge")) {
  //     setEligibilityTabActive("active");
  //   }
  //   if (newTabArray.includes("doc_upload")) {
  //     setDocumentTabActive("active");
  //   }
  // };
  const getClientStep = async () => {
    setLoading(true);
    // setStep("file_hire_new_report");
    const res = await getData("employee_onboarding_steps", {});
    if (res.status === true) {
      setStep(res.data.status);
      setLoading(false);
      setEmployeeObject(res.data);
      setTabsList(res.data.tabs);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    const updateStepFromQuery = () => {
      const queryStep = location.search.split("step=")[1];
      if (queryStep) {
        setStep(queryStep);
      }
    };
    updateStepFromQuery();
  }, [location.search]);

  const getStaticList = async () => {
    setLoading(true);
    const res = await postData("get_constant", {});
    if (res.status === true) {
      setStateList(res.data.stateList);
      setRelationshipList(res.data.emergencyContactList);
      setFederalList(res.data.federalList);
      setEligibilityList(res.data.eligibilityList);
      setCountryList(res.data.countryList);
      setLoading(false);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const getTabTitle = (step) => {
    if (step === "personal_info") {
      setTitle("Personal information");
      setStepNo(1);
    } else if (step === "contact_info") {
      setTitle("Contact information");
      setStepNo(2);
    } else if (step === "employee_agreement") {
      setTitle("Employee agreement");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(3);
      }
    } else if (step === "emergency_contact") {
      setTitle("Emergency contact");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(4);
      } else {
        setStepNo(3);
      }
    } else if (step === "federal_tax") {
      setTitle("Federal tax");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(5);
      } else {
        setStepNo(4);
      }
    } else if (step === "onboarding_tasks") {
      setTitle("Onboarding tasks");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(6);
      } else {
        setStepNo(5);
      }
    } else if (step === "direct_deposit") {
      setTitle("Direct deposit");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(7);
      } else {
        setStepNo(6);
      }
    } else if (step === "file_hire_new_report") {
      setTitle("File new hire report");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(8);
      } else {
        setStepNo(7);
      }
    } else if (step === "eligibility_acknowledge") {
      setTitle("Eligibility and I9 forms");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(9);
      } else {
        setStepNo(8);
      }
    } else if (step === "doc_upload") {
      setTitle("Document upload");
      if (tabsList?.includes("employee_agreement")) {
        setStepNo(10);
      } else {
        setStepNo(9);
      }
    }
  };
  const nextStep = (whichStep) => {
    setStep(whichStep);
    const url = `${location.pathname}?step=${whichStep}`;
    window.history.pushState({}, "", url);
    if (whichStep === "contact_info") {
      setEmpAgreeValue(true);
      setReloadContactInfo(true);
    }
  };

  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  };

  const reloadContactInfoCallback = () => {
    setReloadContactInfo(false); // Reset reload state
  };
  const handleGetEmployeeData = async () => {
    setLoading(true);
    const res = await getData("get-gusto-employee-data", {});
    try {
      setLoading(false);
      if (res.status === true) {
        const queryStep = new URLSearchParams(window.location.search).get(
          "step"
        );
        const step = queryStep || res.data.status;
        setStep(step);
        setLoading(false);
        setEmployeeObject(res.data);
        setTabsList(res.data.tabs);
        setStepArrays(res?.data?.tabs);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };
  useEffect(() => {
    handleGetEmployeeData();
  }, []);
  const handleNavigateOnboarding = async () => {
    setLoading(true);
    const data = {
      step:
        employeeObject?.offer_letter_screen === true
          ? "offer_letter"
          : "personal_info",
    };
    try {
      const res = await postData("gusto-employee-start", {}, data);
      if (res.status === true) {
        setStep(res.data.status);
        setLoading(false);
        setEmployeeObject(res.data);
        setTabsList(res.data.tabs);
      } else {
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.error(err, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleStatusChange = async () => {
    setLoading(true);
    let data = { stage: "document_verification" };
    const res = await postData("gusto-employee-status-update", {}, data);
    if (res.status === true) {
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };
  const signatorySubmit = async () => {
    setLoading(true);
    const url = "gusto-employee-document-verification";
    try {
      const res = await postData(url, {}, {});
      if (res.status === true) {
        setLoading(false);
        nextStep(res?.next_steps);
        setStep(res.data.status);
        setEmployeeObject(res.data);
        setTabsList(res.data.tabs);
        handleStatusChange();
        handleGetEmployeeData();
      }
    } catch (err) {
      toast.error(err, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const stepLabelMap = {
    offer_letter: "Offer Letter",
    personal_info: "Personal Information",
    employee_agreement: "Employee Agreement",
    federal_tax: "Federal Tax",
    onboarding_tasks: "Onboarding Tasks",
    direct_deposit: "Direct Deposit",
    state_tax: "State Tax",
    document_verification: "Document Verification",
    eligibility_acknowledge: "Eligibility Acknowledgement",
    doc_upload: "Document Upload",
    eligibility_i9: "Eligibility & I9 forms",
  };
  useEffect(() => {
    const stepIndex = stepArrays.findIndex((item) => item === step);
    if (stepIndex !== -1) {
      setStepNo(stepIndex + 1);
    }
  }, [step, stepArrays, setStepNo]);

  const getDisplayLabel = (key) => stepLabelMap[key] || key;
  return (
    <>
      {" "}
      {step === "finish_onboarding" ? (
        <EmployeeOnboardingSummary
          nextStep={nextStep}
          step={step}
          clientObject={employeeObject}
          tabsList={tabsList}
          stateList={stateList}
          relationshipList={relationshipList}
          handleGetEmployeeData={handleGetEmployeeData}
          reloadCallback={reloadContactInfoCallback}
          empAgreeTabActive={empAgreeTabActive}
          federalList={federalList}
          signatorySubmit={signatorySubmit}
          eligibilityList={eligibilityList}
          countryList={countryList}
        />
      ) : (
        <>
          {step === "welcome_page" ? (
            <EmployeeWelcomScreen
              employeeObject={employeeObject}
              handleNavigateOnboarding={handleNavigateOnboarding}
            />
          ) : (
            <>
              <HeaderForm />
              <div className="boarding-layout board-main-section">
                <Row>
                  <Col
                    md={getRevertUser() ? 10 : 12}
                    sm={getRevertUser() ? 8 : 12}
                  >
                    <OnboardHeading
                      title="employee"
                      subtitle={newCompanyName}
                    />
                  </Col>
                  {getRevertUser() ? (
                    <Col
                      md={2}
                      sm={getRevertUser() ? 4 : 2}
                      className="d-flex align-items-center justify-content-end mb-4 mb-md-0"
                    >
                      <div className="revert">
                        <Button
                          variant="danger"
                          className="revert_btn"
                          onClick={handleRevertUser}
                        >
                          <img
                            src={revertIcon}
                            alt="revert"
                            className="revert-img me-1"
                          />
                          Revert User
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                {!loading && (
                  <div className="create-board">
                    <div className="board-container">
                      <div className="emp-onboard-tabs onboard-tabs">
                        <div className="Mobi-view justify-content-start">
                          <Steper
                            className="Form-step"
                            max={tabsList?.length}
                            ProgressValue={stepNo}
                          />
                          <h4 className="ms-2 mb-0 align-self-center fw-700">
                            {getDisplayLabel(step)}
                          </h4>
                        </div>
                        <div className="onboard-progress">
                          <ProgressBar
                            step={step}
                            stepArrays={stepArrays}
                            completedSteps={completedSteps}
                            setStepNo={setStepNo}
                          />
                          {/* <h4 className="ms-2 mb-0 align-self-center fw-700">{title}</h4> */}
                        </div>
                        <div className="onboarding-content-wrapper">
                          {step === "offer_letter" && (
                            <OfferLetter
                              nextStep={nextStep}
                              onboardObject={employeeObject}
                              handleGetEmployeeData={handleGetEmployeeData}
                            />
                          )}
                          {step === "personal_info" && (
                            <>
                              <PersonalInformation
                                nextStep={nextStep}
                                onboardObject={employeeObject}
                                stateList={stateList}
                                relationshipList={relationshipList}
                                handleGetEmployeeData={handleGetEmployeeData}
                              />
                            </>
                          )}
                          {step === "employee_agreement" && (
                            <EmployeeAgreement
                              nextStep={nextStep}
                              onboardObject={employeeObject}
                              stateList={stateList}
                              reloadCallback={reloadContactInfoCallback}
                              empAgreeTabActive={empAgreeTabActive}
                              handleGetEmployeeData={handleGetEmployeeData}
                            />
                          )}
                          {/* {step === "employee_agreement" && (
                    <EmployeeAgreement
                      nextStep={nextStep}
                      onboardObject={employeeObject}
                      stateList={stateList}
                      reloadCallback={reloadContactInfoCallback}
                    />
                  )} */}
                          {step === "direct_deposit" && (
                            <DirectDeposit
                              nextStep={nextStep}
                              onboardObject={employeeObject}
                              handleGetEmployeeData={handleGetEmployeeData}
                              tabsList={tabsList}
                            />
                          )}
                          {step === "federal_tax" && (
                            <FederalTaxSetup
                              nextStep={nextStep}
                              onboardObject={employeeObject}
                              federalList={federalList}
                              handleGetEmployeeData={handleGetEmployeeData}
                            />
                          )}
                          {step === "onboarding_tasks" && (
                            <OnboardingsTask
                              nextStep={nextStep}
                              onboardObject={employeeObject}
                              handleGetEmployeeData={handleGetEmployeeData}
                            />
                          )}

                          {step === "state_tax" && (
                            <>
                              <StatetaxTab
                                userId={employeeObject?.user_id}
                                onboardObject={employeeObject}
                                onboardingForm
                                nextStep={nextStep}
                                handleGetEmployeeData={handleGetEmployeeData}
                                setStep={setStep}
                              />
                            </>
                          )}
                          {step === "document_verification" && (
                            <>
                              <DocumentVerification
                                nextStep={nextStep}
                                signatorySubmit={signatorySubmit}
                                loading={loading}
                                handleGetEmployeeData={handleGetEmployeeData}
                              />
                              {/* <EmployeeSignDocuments /> */}
                            </>
                          )}
                          {step === "eligibility_i9" && (
                            <EligibilityForm
                              nextStep={nextStep}
                              onboardObject={employeeObject}
                              eligibilityList={eligibilityList}
                              countryList={countryList}
                              handleGetEmployeeData={handleGetEmployeeData}
                              setStep={setStep}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {loading && <Loader />}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EmployeeOnboardingForms;
